var $window, $body;
$(function() { initialise(); });

function initialise() {
  //Setup the branch selection on load
  $window = $(window);
  $body = $("body");

  $('table.week').DataTable({
    "autoWidth": false,
    "aLengthMenu": [
        [10, 25, 50, 100, -1],
        [10, 25, 50, 100, "All"]
    ],
    "iDisplayLength": -1,
    "order": [],
  });

  $('table thead tr.date-row').click(function () {
    $(this).toggleClass('hidden');
    $(this).parent('thead').next().toggleClass('hidden');
  });

  // handles clicks on link to calendar key
  $("#calendar-key-link").click(function() {
    $("#calendar-key-content").slideToggle(500);
  });
}